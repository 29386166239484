import * as Contract from '@tableau/api-external-contract-js';

import { TableauEvent } from './TableauEvent';

export class WorkbookFormattingChangedEvent extends TableauEvent implements Contract.WorkbookFormattingChangedEvent {
  public constructor(private _formatting: Contract.WorkbookFormatting) {
    super(Contract.TableauEventType.WorkbookFormattingChanged);
  }

  public get formatting(): Contract.WorkbookFormatting {
    return this._formatting;
  }
}
