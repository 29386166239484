import { TableauEventType } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';

import { TableauSheetEvent } from './TableauSheetEvent';

export class TableauWorksheetEvent extends TableauSheetEvent implements Contract.TableauWorksheetEvent {
  public get worksheet(): Contract.ExtensionWorksheet {
    return this._worksheet;
  }

  public constructor(type: TableauEventType, protected _worksheet: Contract.ExtensionWorksheet) {
    super(type, _worksheet);
  }
}
