import { FilterUpdateType, SelectionUpdateType, TableauEventType } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';

import { Sheet } from './Sheet';
import {
  ApiServiceRegistry,
  NotificationService,
  ServiceNames,
  SingleEventManager,
  SingleEventManagerImpl,
  WorksheetImpl
} from '@tableau/api-shared-js';
import { Column } from '@tableau/api-external-contract-js';
import { FilterEvent, NotificationId, VisualId } from '@tableau/api-internal-contract-js';
import { MarksSelectedEvent } from '../Events/MarksSelectedEvent';
import { FilterChangedEvent } from '../Events/FilterChangedEvent';
import { Dashboard } from './Dashboard';

export class Worksheet extends Sheet implements Contract.ExtensionWorksheet {
  public constructor(protected _worksheetImpl: WorksheetImpl) {
    super(_worksheetImpl);

    // Call to initialize events and then call down to the event listener manager to handle things
    this.initializeEvents().forEach(e => this.addNewEventType(e));
  }

  public get parentDashboard(): Contract.ExtensionDashboard {
    return new Dashboard(this._worksheetImpl.parentDashboard);
  }


  /**
   * Helper method which goes through and registers each event type this impl knows about
   * with the NotificationService. It returns an array of SingleEventManager objects which
   * can then be passed to an EventListenerManager to handle user registration / unregistration.
   *
   * @param {Worksheet} worksheet The worksheet object which will be included with the event notifications
   * @returns {Array<SingleEventManager>} Collection of event managers to pass to an EventListenerManager
   */
  public initializeEvents(): Array<SingleEventManager> {
    const results = new Array<SingleEventManager>();
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.get().getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return
      return results;
    }

    // Initialize all of the event managers we'll need (one for each event type)
    const marksEvent = new SingleEventManagerImpl<MarksSelectedEvent>(TableauEventType.MarkSelectionChanged);
    notificationService.registerHandler(NotificationId.SelectedMarksChanged, (model) => {
      const visualId = model as VisualId;
      return this.visualIdsAreEqual(visualId, this._worksheetImpl.visualId);
    }, (viz: VisualId) => {
      marksEvent.triggerEvent(() => new MarksSelectedEvent(this));
    });

    const filterEvent = new SingleEventManagerImpl<FilterChangedEvent>(TableauEventType.FilterChanged);
    notificationService.registerHandler(NotificationId.FilterChanged, (model) => {
      const filterEventResponse = model as FilterEvent;
      return this._worksheetImpl.visualId.worksheet === filterEventResponse.visualId.worksheet;
    }, (event: FilterEvent) => {
      filterEvent.triggerEvent(() => new FilterChangedEvent(this, event.fieldName));
    });

    results.push(marksEvent);
    results.push(filterEvent);

    // TODO - other event types

    return results;
  }

  public applyFilterAsync(
    fieldName: string, values: Array<string>, updateType: FilterUpdateType, options: Contract.FilterOptions): Promise<string> {
    return this._worksheetImpl.applyFilterAsync(fieldName, values, updateType, options);
  }

  public applyRangeFilterAsync(fieldName: string, filterOptions: Contract.RangeFilterOptions): Promise<string> {
    return this._worksheetImpl.applyRangeFilterAsync(fieldName, filterOptions);
  }

  public clearFilterAsync(fieldName: string): Promise<string> {
    return this._worksheetImpl.clearFilterAsync(fieldName);
  }

  public getDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    return this._worksheetImpl.getDataSourcesAsync();
  }

  public getFiltersAsync(): Promise<Array<Contract.Filter>> {
    return this._worksheetImpl.getFiltersAsync();
  }

  public getSelectedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getSelectedMarksAsync();
  }

  public getHighlightedMarksAsync(): Promise<Contract.MarksCollection> {
    return this._worksheetImpl.getHighlightedMarksAsync();
  }

  public getSummaryDataAsync(options: Contract.GetSummaryDataOptions): Promise<Contract.DataTable> {
    return this._worksheetImpl.getSummaryDataAsync(options);
  }

  public getSummaryColumnsInfoAsync(): Promise<Array<Column>> {
    return this._worksheetImpl.getSummaryColumnsInfoAsync();
  }

  public getUnderlyingDataAsync(options: Contract.GetUnderlyingDataOptions): Promise<Contract.DataTable> {
    console.warn('Worksheet.getUnderlyingDataAsync is deprecated. Please use ' +
      'Worksheet.getUnderlyingTablesAsync and Worksheet.getUnderlyingTableDataAsync');
    return this._worksheetImpl.getUnderlyingDataAsync(options);
  }

  public getUnderlyingTablesAsync(): Promise<Array<Contract.LogicalTable>> {
    return this._worksheetImpl.getUnderlyingTablesAsync();
  }

  public getUnderlyingTableDataAsync(logicalTableId: string, options: Contract.GetUnderlyingDataOptions):
    Promise<Contract.DataTable> {
    return this._worksheetImpl.getUnderlyingTableDataAsync(logicalTableId, options);
  }

  public clearSelectedMarksAsync(): Promise<void> {
    return this._worksheetImpl.clearSelectedMarksAsync();
  }

  public selectMarksByIDAsync(marksInfo: Array<Contract.MarkInfo>, updateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByIdAsync(marksInfo, updateType);
  }

  public selectMarksByValueAsync(selections: Array<Contract.SelectionCriteria>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByValueAsync(selections, selectionUpdateType);
  }

  public selectMarksByIdAsync(selections: Array<Contract.MarkInfo>,
    selectionUpdateType: SelectionUpdateType): Promise<void> {
    return this._worksheetImpl.selectMarksByIdAsync(selections, selectionUpdateType);
  }

  private visualIdsAreEqual(a: VisualId, b: VisualId): boolean {
    return a && b &&
      a.worksheet === b.worksheet &&
      a.dashboard === b.dashboard &&
      a.storyboard === b.storyboard &&
      a.storyPointID === b.storyPointID;
  }
}
