import * as Contract from '@tableau/api-external-contract-js';
import { SheetType } from '@tableau/api-external-contract-js';
import { EventListenerManager, SheetImpl, Size, TableauError } from '@tableau/api-shared-js';
import { Parameter } from './Parameter';

export class Sheet extends EventListenerManager implements Contract.ExtensionSheet {
  public constructor(private _sheetImpl: SheetImpl) {
    super();
  }

  public get name(): string {
    return this._sheetImpl.name;
  }

  public get sheetType(): SheetType {
    return this._sheetImpl.sheetType;
  }

  public get size(): Contract.Size {
    if (this._sheetImpl.size instanceof Size) {
      return this._sheetImpl.size;
    }
    throw new TableauError(Contract.EmbeddingErrorCodes.ImplementationError, 'Size not implemented');
  }

  public findParameterAsync(parameterName: string): Promise<Contract.Parameter | undefined> {
    return this._sheetImpl.findParameterAsync(parameterName).then((parameterImpl) => {
      if (parameterImpl) {
        return new Promise<Contract.Parameter>((resolve) => resolve(new Parameter(parameterImpl, this)));
      } else {
        return new Promise<undefined>((resolve) => resolve(undefined));
      }
    });
  }

  public getParametersAsync(): Promise<Array<Contract.Parameter>> {
    return this._sheetImpl.getParametersAsync().then((parametersImpl) => {
      return parametersImpl.map(parameterImpl => {
        return new Parameter(parameterImpl, this);
      });
    });
  }
}
