import {
  InitializationOptions,
  InternalApiDispatcherFactory,
  INTERNAL_CONTRACT_VERSION,
  VerbId
} from '@tableau/api-internal-contract-js';

/**
 * LegacyInternalApiDispatcherHolder has been moved (and renamed) from api-internal-contract.
 * It supports running a newer external library against a pre 2019.3 desktop.
 * Starting in 2019.3, we have a merged bootstrap style for both desktop and server.
 * In addition, the alpha checking that is happening here is not necessary in external-api-js
 *  after 2019.3 because that checking happens in api-platform-js (ApiDispatcherTest.ts)
 * TFSID: 1091561 tracks an improvement to this system to make it less complex and tie it directly to
 *  tableau beta release versions.
 * @deprecated
 */
export namespace LegacyInternalApiDispatcherHolder {
  /**
   * @param options
   */
  export function getDesktopDispatcherPromise(options?: InitializationOptions): Promise<InternalApiDispatcherFactory> | undefined {
    if ((!options || typeof options.isAlpha === 'undefined') && !window.__warningIssued) {
      // tslint:disable-next-line:no-console
      console.warn('This is a beta version of the Extensions API. Please upgrade to an official release.');
      window.__warningIssued = true;
    } else if (options && options.isAlpha! && window.__platformIsOfficialRelease) {
      window.__tableauDesktopDispatcher.then((dispatcherFactory) => {
        const dispatcher = dispatcherFactory(INTERNAL_CONTRACT_VERSION);
        dispatcher.execute(VerbId.BlockExtension, {}).catch();
      }).catch();
    }

    // this will be undefined if promise is rejected or throws
    return window.__tableauDesktopDispatcher;
  }

  /**
   * @param options
   */
  export function hasDesktopApiDispatcherPromise(options?: InitializationOptions): boolean {
    return !!getDesktopDispatcherPromise(options);
  }
}

declare global {
  interface Window {
    __tableauDesktopDispatcher: Promise<InternalApiDispatcherFactory>;
    __platformIsOfficialRelease: boolean;
    __warningIssued: boolean;
  }
}
