import {
  InternalApiDispatcher,
} from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry } from '@tableau/api-shared-js';

import { InitializationServiceImpl } from './Impl/InitializationServiceImpl';
import { SettingsServiceImpl } from './Impl/SettingsServiceImpl';
import { UIServiceImpl } from './Impl/UIServiceImpl';

export function registerAllExtensionsServices(dispatcher: InternalApiDispatcher): void {
  ApiServiceRegistry.get().registerService(new SettingsServiceImpl(dispatcher));
  ApiServiceRegistry.get().registerService(new UIServiceImpl(dispatcher));
}

export function registerInitializationExtensionsServices(dispatcher: InternalApiDispatcher): void {
  ApiServiceRegistry.get().registerService(new InitializationServiceImpl(dispatcher));
}
