import { TableauEventType } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';

export class TableauEvent implements Contract.TableauEvent {
  private _type: TableauEventType;

  public constructor(type: TableauEventType) {
    this._type = type;
  }

  public get type(): TableauEventType {
    return this._type;
  }
}
