import { TableauEventType, ErrorCodes } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';
import { ApiServiceRegistry, ServiceNames, TableauError, ParametersService } from '@tableau/api-shared-js';
import { TableauSheetEvent } from './TableauSheetEvent';
import { Parameter } from '../Models/Parameter';

export class ParameterChangedEvent extends TableauSheetEvent implements Contract.ParameterChangedEvent {
  public constructor(private _globalFieldName: string, sheet: Contract.ExtensionSheet) {
    super(TableauEventType.ParameterChanged, sheet);
  }

  public getParameterAsync(): Promise<Contract.Parameter> {
    // Call down to our service to get the parameter back via its field name
    const service = ApiServiceRegistry.get().getService<ParametersService>(ServiceNames.Parameters);
    return service.findParameterByGlobalFieldNameAsync(this._globalFieldName).then(parameterImpl => {
      if (parameterImpl === undefined) {
        throw new TableauError(ErrorCodes.MissingParameter, `Cannot find parameter: ${this._globalFieldName}`);
      }

      return new Parameter(parameterImpl, this.sheet);
    });
  }
}
