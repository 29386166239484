import * as Contract from '@tableau/api-external-contract-js';

import { UIImpl } from '../Impl/UIImpl';

/**
 * Implementation of the external UI namespace.
 */
export class UI implements Contract.UI {
  public constructor(private _impl: UIImpl) { }

  public displayDialogAsync(url: string, payload?: string, options?: Contract.DialogOptions): Promise<string> {
    return this._impl.displayDialogAsync(url, payload, options);
  }

  public closeDialog(payload?: string): void {
    this._impl.closeDialog(payload);
  }
}
