import * as Contract from '@tableau/api-external-contract-js';
import { ExtensionWorkbookImpl } from '../Impl/ExtensionWorkbookImpl';


export class Workbook implements Contract.ExtensionWorkbook {
  public constructor(private _workbookImpl: ExtensionWorkbookImpl) {
  }

  public getAllDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    return this._workbookImpl.getAllDataSourcesAsync();
  }
}
