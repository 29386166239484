import { ErrorCodes } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';

import { DialogUpdateEvent, NotificationId } from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  NotificationService,
  ServiceNames,
  TableauError
} from '@tableau/api-shared-js';

import { ExtensionsServiceNames } from '../Services/ExtensionsServiceNames';
import { UIService } from '../Services/UIService';

export class UIImpl {
  public displayDialogAsync(url: string, payload?: string, options?: Contract.DialogOptions): Promise<string> {
    const uiService = ApiServiceRegistry.get().getService<UIService>(ExtensionsServiceNames.UIService);
    const notificationService: NotificationService = ApiServiceRegistry.get().getService<NotificationService>(ServiceNames.Notification);

    return new Promise((resolve, reject) => {
      uiService.displayDialogAsync(url, payload || '', options).then(() => {
        const unregisterFn = notificationService.registerHandler(NotificationId.ExtensionDialogUpdate, (model) => {
          return true; // Let through any dialog update event
        }, (event: DialogUpdateEvent) => {
          if (event.isCloseEvent) {
            resolve(event.closePayload);
          } else {
            reject(new TableauError(ErrorCodes.DialogClosedByUser, 'Extension dialog closed by user.'));
          }

          unregisterFn();
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  public closeDialog(payload?: string): void {
    const uiService = ApiServiceRegistry.get().getService<UIService>(
      ExtensionsServiceNames.UIService);

    uiService.closeDialog(payload);
  }
}
