import { TableauEventType } from '@tableau/api-external-contract-js';
import * as Contract from '@tableau/api-external-contract-js';

import { TableauWorksheetEvent } from './TableauWorksheetEvent';

export class MarksSelectedEvent extends TableauWorksheetEvent implements Contract.MarksSelectedEvent {
  public constructor(worksheet: Contract.ExtensionWorksheet) {
    super(TableauEventType.MarkSelectionChanged, worksheet);
  }

  public getMarksAsync(): Promise<Contract.MarksCollection> {
    return this.worksheet.getSelectedMarksAsync();
  }
}
