import { Settings as SettingsContract } from '@tableau/api-external-contract-js';
import { EventListenerManager } from '@tableau/api-shared-js';

import { SettingsImpl } from '../Impl/SettingsImpl';
import { SettingsCollection } from '../Services/SettingsService';

/**
 * Implementation of the external settings namespace.
 */
export class Settings extends EventListenerManager implements SettingsContract {
  public constructor(private _settingsImpl: SettingsImpl) {
    super();

    // Initialize our event handling for this class
    this._settingsImpl.initializeEvents().forEach(e => this.addNewEventType(e));
  }

  public erase(key: string): void {
    this._settingsImpl.erase(key);
  }

  public get(key: string): string | undefined {
    return this._settingsImpl.get(key);
  }

  public getAll(): SettingsCollection {
    return this._settingsImpl.getAll();
  }

  public get isModified(): boolean {
    return this._settingsImpl.isModified;
  }

  public saveAsync(): Promise<SettingsCollection> {
    return this._settingsImpl.saveAsync();
  }

  public set(key: string, value: string): void {
    this._settingsImpl.set(key, value);
  }
}
