import { ErrorCodes, SheetType } from '@tableau/api-external-contract-js';
import {
  ApiServiceRegistry,
  DashboardImpl,
  doCrossFrameBootstrap,
  NotificationService,
  registerAllSharedServices,
  ServiceNames,
  SheetInfoImpl,
  Size
} from '@tableau/api-shared-js';

import {
  Dashboard
} from '../Models/Dashboard';
import { DashboardContent } from '../Namespaces/DashboardContent';
import { Environment } from '../Namespaces/Environment';
import { ExtensionsServiceNames } from '../Services/ExtensionsServiceNames';
import { InitializationService } from '../Services/InitializationService';
import { UIService } from '../Services/UIService';
import { registerAllExtensionsServices, registerInitializationExtensionsServices } from '../Services/RegisterAllExtensionsServices';
import { Settings } from '../Namespaces/Settings';
import { SettingsImpl } from './SettingsImpl';
import { TableauError } from '@tableau/api-shared-js';
import { UI } from '../Namespaces/UI';
import { UIImpl } from './UIImpl';
import { ApiVersion, VizService } from '@tableau/api-shared-js';
import { VersionedExternalApiDispatcher } from '@tableau/api-shared-js';

import {
  ContextMenuEvent,
  ExtensionDashboardInfo,
  ExtensionSettingsInfo,
  InternalApiDispatcherFactory,
  NotificationId,
  SheetPath,
  WorkbookFormatting,
  INTERNAL_CONTRACT_VERSION,
  InitializationOptions,
  InternalApiDispatcher,
  VerbId,
  ExecuteParameters,
  ParameterId,
  FontNameAndInfo,
  FormattingSheet,
} from '@tableau/api-internal-contract-js';
import { LegacyInternalApiDispatcherHolder } from './LegacyInternalApiDispatcherHolder';
import { Workbook } from '../Models/Workbook';
import { ExtensionWorkbookImpl } from './ExtensionWorkbookImpl';

export type CallbackMap = { [key: string]: () => {} };

export class ExtensionsImpl {
  private _initializationPromise: Promise<string>;
  private _styleElement: HTMLStyleElement;
  public dashboardContent: DashboardContent;
  public environment: Environment;
  public settings: Settings;
  public ui: UI;
  public workbook: Workbook;
  public extensionZoneId: number;

  public initializeAsync(isExtensionDialog: boolean, contextMenuCallbacks?: CallbackMap): Promise<string> {
    if (!this._initializationPromise) {
      this._initializationPromise = new Promise<string>((resolve, reject) => {
        const initOptions: InitializationOptions = { isAlpha: ApiVersion.Instance.isAlpha };
        // First thing we want to do is check to see if there is a desktop dispatcher already registered for us
        if (LegacyInternalApiDispatcherHolder.hasDesktopApiDispatcherPromise(initOptions)) {
          // Running in a pre-2019.3 desktop, use our legacy dispatcher promise
          const desktopDispatcherPromise = LegacyInternalApiDispatcherHolder.getDesktopDispatcherPromise(initOptions);
          desktopDispatcherPromise!.then((dispatcherFactory) =>
            this.onDispatcherReceived(dispatcherFactory, isExtensionDialog, contextMenuCallbacks))
            .then((openPayload) => {
              resolve(openPayload);
            }).catch((error) => {
              reject(error);
            });
        } else {
          // We must be running in server, so we should try to kick of the server dispatcher bootstrapping
          const onDispatcherReceivedCallback = this.onDispatcherReceived.bind(this);
          doCrossFrameBootstrap(window, INTERNAL_CONTRACT_VERSION, initOptions).then((factory: InternalApiDispatcherFactory) => {
            return onDispatcherReceivedCallback(factory, isExtensionDialog, contextMenuCallbacks);
          }).then((openPayload) => {
            resolve(openPayload);
          }).catch((error) => {
            reject(error);
          });
        }
      });
    }

    return this._initializationPromise;
  }

  public createVizImageAsync(inputSpec: object): Promise<string> {
    const vizService = ApiServiceRegistry.get().getService<VizService>(ServiceNames.Viz);

    return vizService.createVizImageAsync(inputSpec);
  }

  public setClickThroughAsync(clickThroughEnabled: boolean): Promise<void> {
    const uiService = ApiServiceRegistry.get().getService<UIService>(ExtensionsServiceNames.UIService);

    return uiService.setClickThroughAsync(clickThroughEnabled, this.extensionZoneId);
  }

  private onDispatcherReceived(
    dispatcherFactory: InternalApiDispatcherFactory,
    isExtensionDialog: boolean,
    contextMenuFunctions?: CallbackMap): Promise<string> {

    let dispatcher: InternalApiDispatcher = dispatcherFactory(INTERNAL_CONTRACT_VERSION);

    // Call to register all the services which will use the newly initialized dispatcher
    registerInitializationExtensionsServices(dispatcher);

    // Get the initialization service and initialize this extension
    const initializationService = ApiServiceRegistry.get().getService<InitializationService>(
      ExtensionsServiceNames.InitializationService);

    const callbackMapKeys = (contextMenuFunctions) ? Object.keys(contextMenuFunctions) : [];
    return initializationService.initializeDashboardExtensionsAsync(isExtensionDialog, callbackMapKeys).then<string>(result => {
      if (!result.extensionInstance.locator.dashboardPath) {
        throw new TableauError(ErrorCodes.InternalError, 'Unexpected error during initialization.');
      }

      // If we receive an invalid plaform version, this means that platform is runnning 1.4 or 2.1 and
      // doesn't pass the platform version to external. In this case we assume the platform version to be 1.9
      const platformVersion = result.extensionEnvironment.platformVersion
        ? result.extensionEnvironment.platformVersion
        : { major: 1, minor: 9, fix: 0 };

      // Wrap our existing dispatcher in a dispatcher that can downgrade/upgrade for an older platform.
      if (VersionedExternalApiDispatcher.needsVersionConverter(platformVersion)) {
        dispatcher = new VersionedExternalApiDispatcher(dispatcher, platformVersion);
      }
      // Registration of services must happen before initializing content and environment
      // Extensions doesn't need to pass in a registryId. By default, the service registry instance is associated with registryId=0.
      registerAllSharedServices(dispatcher, undefined, platformVersion);
      registerAllExtensionsServices(dispatcher);

      this.dashboardContent = this.initializeDashboardContent(
        result.extensionDashboardInfo,
        result.extensionInstance.locator.dashboardPath);

      this.environment = new Environment(result.extensionEnvironment);
      this.settings = this.initializeSettings(result.extensionSettingsInfo);
      this.ui = new UI(new UIImpl());
      this.workbook = new Workbook(new ExtensionWorkbookImpl());
      this.extensionZoneId = result.extensionDashboardInfo.extensionZoneId;

      if (result.extensionEnvironment.workbookFormatting) {
        this.initializeTableauFonts(dispatcher, result.extensionEnvironment.workbookFormatting);
        this.applyAllFormatting(result.extensionEnvironment.workbookFormatting);
      }

      // After initialization has completed, setup listeners for the callback functions that
      // are meant to be triggered whenever a context menu item is clicked.
      this.initializeContextMenuCallbacks(contextMenuFunctions);

      // Also set up listeners for ExtensionStylesChangedEvent
      this.initializeWorkbookFormattingChangedEventCallback(dispatcher);

      // In the normal initialization case, this will be an empty string.  When returning from initializeAsync to the
      // developer, we just ingore that string.  In the case of initializing from an extension dialog, this string
      // is an optional payload sent from the parent extension.
      return result.extensionDialogPayload;
    });
  }

  public applyAllFormatting(formattingModel: WorkbookFormatting | undefined): void {
    if (!this._styleElement) {
      this._styleElement = document.createElement('style');
      this._styleElement.id = 'Tableau-Extension-Formatting';
      document.head.appendChild(this._styleElement);
    }

    if (!this._styleElement.sheet || !formattingModel) {
      return;
    }

    const stylesheet: CSSStyleSheet = this._styleElement.sheet as CSSStyleSheet;
    while (stylesheet.cssRules.length > 0) {
      stylesheet.deleteRule(stylesheet.cssRules.length - 1);
    }
    formattingModel.formattingSheets.map(currentFormattingSheet => {
      this.applyFormattingSheetToStyleSheet(currentFormattingSheet, stylesheet);
    });
  }

  private applyFormattingSheetToStyleSheet(currentFormattingSheet: FormattingSheet, stylesheet: CSSStyleSheet): void {
    let styleInfo: string = '';

    const cssProperties = currentFormattingSheet.cssProperties;
    if (cssProperties.fontFamily) {
      styleInfo += 'font-family: ' + cssProperties.fontFamily.toString() + '; ';
    }

    if (cssProperties.fontSize) {
      styleInfo += 'font-size: ' + cssProperties.fontSize.toString() + '; ';
    }

    if (cssProperties.fontWeight) {
      styleInfo += 'font-weight: ' + cssProperties.fontWeight.toString() + '; ';
    }

    if (cssProperties.fontStyle) {
      styleInfo += 'font-style: ' + cssProperties.fontStyle.toString() + '; ';
    }

    if (cssProperties.textDecoration) {
      styleInfo += 'text-decoration: ' + cssProperties.textDecoration.toString() + '; ';
    }

    if (cssProperties.color) {
      styleInfo += 'color: ' + cssProperties.color + '; ';
    }

    const selector: string = '.' + currentFormattingSheet.classNameKey;
    const rule: string = selector + ' { ' + styleInfo + ' }';
    stylesheet.insertRule(rule, stylesheet.cssRules.length);
  }

  private initializeWorkbookFormattingChangedEventCallback(dispatcher: InternalApiDispatcher): void {
    const notificationService: NotificationService = ApiServiceRegistry.get().getService<NotificationService>(ServiceNames.Notification);
    // Unregister function not used since these notifications should be
    // observed for the full lifetime of the extension.
    notificationService.registerHandler(NotificationId.WorkbookFormattingChanged, (model) => {
      return true;
    }, (eventFormatting: WorkbookFormatting) => {
      if (eventFormatting) {
        this.initializeTableauFonts(dispatcher, eventFormatting);
        this.applyAllFormatting(eventFormatting);
      }
    });
  }

  private initializeTableauFonts(dispatcher: InternalApiDispatcher, workbookFormatting: WorkbookFormatting): void {
    const fontNames = new Array();
    workbookFormatting.formattingSheets.forEach((formattingSheet) => {
      if (formattingSheet.cssProperties.fontFamily) {
        fontNames.push(formattingSheet.cssProperties.fontFamily);
      }
    });

    if (fontNames.length > 0) {
      const parameters: ExecuteParameters = { [ParameterId.FontNameListItems]: fontNames };
      dispatcher.execute(VerbId.GetFonts, parameters).then((response) => {
        this.loadFonts(response.result as Array<FontNameAndInfo>);
      }).catch();
    }
  }

  private loadFonts(fonts: Array<FontNameAndInfo>): void {
    fonts.forEach((fontNameAndInfo) => {
      const fontFace = new FontFace(fontNameAndInfo.fontName, fontNameAndInfo.fontBinaryInfo);
      document.fonts.add(fontFace);
    });
  }

  private initializeDashboardContent(info: ExtensionDashboardInfo, sheetPath: SheetPath): DashboardContent {
    const sheetInfoImpl = new SheetInfoImpl(info.name, SheetType.Dashboard, new Size(info.size.h, info.size.w));
    const dashboardImpl = new DashboardImpl(sheetInfoImpl, info.zones, sheetPath);
    const dashboard = new Dashboard(dashboardImpl);
    return new DashboardContent(dashboard);
  }

  private initializeSettings(settingsInfo: ExtensionSettingsInfo): Settings {
    const settingsImpl = new SettingsImpl(settingsInfo);
    return new Settings(settingsImpl);
  }

  private initializeContextMenuCallbacks(contextMenuFunctions?: CallbackMap): void {
    const notificationService: NotificationService = ApiServiceRegistry.get().getService<NotificationService>(ServiceNames.Notification);

    // Unregister function not used since these notifications should be
    // observed for the full lifetime of the extension.
    notificationService.registerHandler(NotificationId.ContextMenuClick, (model) => {
      // Let through any context menu event, these are already filtered on api-core
      // based on the extension locator.
      return true;
    }, (event: ContextMenuEvent) => {
      // Execute the function associated with this context menu ID
      if (contextMenuFunctions) {
        if (!contextMenuFunctions[event.id]) {
          throw new TableauError(ErrorCodes.InternalError, `Received unexpected context menu Id from event: ${event.id}`);
        }

        contextMenuFunctions[event.id]();
      }
    });
  }
}
