import * as Contract from '@tableau/api-external-contract-js';

/**
 * Implementation of the external DashboardContent namespace.
 * This does not follow the Impl pattern as DashboardContent is
 * currently just a (single) property bag.
 */
export class DashboardContent implements Contract.DashboardContent {
  public constructor(private _dashboard: Contract.ExtensionDashboard) { }

  public get dashboard(): Contract.ExtensionDashboard {
    return this._dashboard;
  }
}
